<template>
  <div>
    <ValidationObserver
      ref="botSettings"
      v-slot="{ handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('agent.title')"
          :rules="isDisplayAll ? 'required' : null"
        >
          <b-form-group
            v-if="isDisplayAll"
            :label="`${$t('agent.title')} ${isDisplayAll ? '*' : ''}`"
            label-for="agent"
          >
            <b-form-select
              v-model="selectedAgent"
              :state="errors[0] ? false : null"
            >
              <b-form-select-option :value="null">{{ $t('fields.click_select') }}
              </b-form-select-option>
              <b-form-select-option
                v-for="(agent, key) in agentList"
                :key="key"
                :value="agent"
              >{{ `(${agent.prefix}) ${agent.name}` }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          :label="$t('fields.type')"
          label-for="type"
        >
          <b-form-radio
            v-model="selectedType"
            name="is_bet_balance"
            value="is_bet_balance"
          >
            {{ $t('cashback.is_bet_balance') }}
          </b-form-radio>
          <b-form-radio
            v-model="selectedType"
            name="is_loss_balance"
            value="is_loss_balance"
          >
            {{ $t('cashback.is_loss_balance') }}
          </b-form-radio>
        </b-form-group>

        <b-form-group
          :label="$t('cashback.start_date')"
          label-for="start_date"
        >
          <b-input-group>
            <template #prepend>
              <b-form-select
                id="period"
                v-model="cashbackForm.intervalUnit"
                :options="periodOptions"
              >
              </b-form-select>
            </template>
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-input
                id="start_date"
                v-model="cashbackForm.startDate"
                v-mask="'####-##-##'"
                :state="errors[0] ? false : null"
                placeholder="YYYY-MM-DD (2021/01/01)"
              ></b-form-input>
            </ValidationProvider>
          </b-input-group>
        </b-form-group>

        <b-form-group
          :label="$t('cashback.cashback_value')"
          label-for="cashback"
        >
          <b-input-group :append="cashbackForm.cashbackType === 'percentage' ? '%' : '฿'">
            <template #prepend>
              <b-form-select
                id="cashback"
                v-model="cashbackForm.cashbackType"
                :options="typeOptions"
              >
              </b-form-select>
            </template>
            <b-input
              v-model="cashbackForm.cashbackValue"
              type="text"
            >
            </b-input>
          </b-input-group>
          <b-form-text>เช่น 10% = ผู้เล่นเสีย 100 บาท จะได้เงินคืน 10 บาท
          </b-form-text>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('cashback.max_return')"
              label-for="max_return"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="max_return"
                  v-model="cashbackForm.maximumReturn"
                ></b-form-input>
              </b-input-group>
              <b-form-text>เช่นตั้งไว้ 500 = ผู้เล่นเสีย 6,000 บาท จะได้เงินคืน 500 บาท
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('cashback.min_return')"
              label-for="min_return"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="min_return"
                  v-model="cashbackForm.minimumReturn"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('cashback.max_cashback_per_round')"
              label-for="max_cashback_per_round"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="max_cashback_per_round"
                  v-model="cashbackForm.maximumCashbackPerRound"
                ></b-form-input>
              </b-input-group>
              <b-form-text>N/A
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('cashback.min_cashback_per_round')"
              label-for="min_cashback_per_round"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="min_cashback_per_round"
                  v-model="cashbackForm.minimumCashbackPerRound"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-form-group
          :label="$t('cashback.turnover_point')"
          label-for="turn_over"
        >
          <b-input-group>
            <b-form-input
              id="turn_over"
              v-model="cashbackForm.turningPoint"
            ></b-form-input>
          </b-input-group>
          <b-form-text>จะนำยอดตัวเลข คืนยอดเสีย เอาไปคูณ เช่น เทิร์น 10 เท่า คืนยอดเสีย
            150 ลูกค้าต้องเทิร์น 1,500
          </b-form-text>
        </b-form-group> -->
        <b-form-group
          :label="$t('cashback.per_people_per_day')"
          label-for="per_people_per_day"
        >
          <b-input-group>
            <b-form-input
              id="per_people_per_day"
              v-model="cashbackForm.cashbackPerPeoplePerDay"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('cashback.agent_credit')"
          label-for="agent_credit"
        >
          <b-input-group>
            <b-form-input
              id="agent_credit"
              v-model="cashbackForm.agentCredit"
              :disabled="!isMaster"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('cashback.game_type')"
          label-for="game_type"
        >
          <b-input-group>
            <b-form-select
              id="game_type"
              v-model="cashbackForm.gameType"
              :options="gameTypeOptions"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('fields.status')"
          label-for="status"
        >
          <b-form-radio-group
            id="status"
            v-model="cashbackForm.isActive"
            name="status"
          >
            <b-form-radio :value="true">
              {{ $t('utils.status.enable') }}
            </b-form-radio>
            <b-form-radio :value="false">
              {{ $t('utils.status.disable') }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="light"
            class="mr-2"
            @click="$emit('cancel')"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoading"
            rounded
            spinner-small
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="primary"
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

export default {
  name: 'CashbackForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedType: 'is_bet_balance',
      periodOptions: [
        { text: 'รายวัน', value: 'day' },
        { text: 'ต่อสัปดาห์', value: 'week' },
        { text: 'ต่อเดือน', value: 'month' },
      ],
      typeOptions: [
        { text: this.$t('fields.amount'), value: 'amount' },
        { text: this.$t('fields.percentage'), value: 'percentage' },
      ],
      selectedAgent: null,
      cashbackForm: {
        agentId: '',
        agentUsername: '',
        agentCredit: 0,
        cashbackType: 'percentage',
        cashbackValue: 0,
        // turningPoint: 0,
        intervalUnit: 'day',
        startDate: dayjs().format('YYYY-MM-DD'),
        minimumReturn: 0,
        maximumReturn: 0,
        isBetBalance: false,
        isLossBalance: false,
        isActive: true,
        minimumCashbackPerRound: 0,
        maximumCashbackPerRound: 0,
        gameType: '',
        cashbackPerPeoplePerDay: 0, 
      },
      gameTypeOptions: [
        {
          value: 'casino',
          text: 'Casino',
        },
        {
          value: 'slot',
          text: 'Slot',
        },
        {
          value: 'sport',
          text: 'Sport',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isMaster', 'agents', 'isMaster', 'isOwner']),
    agentList() {
      return this.agents.items || []
    },
    isDisplayAll() {
      return !!(this.isOwner || this.isMaster)
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      if (this.value && this.value.id) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.cashbackForm = { ...this.cashbackForm, ...data }
        if (data.isBetBalance) {
          this.selectedType = 'is_bet_balance'
        }
        if (data.isLossBalance) {
          this.selectedType = 'is_loss_balance'
        }
        if (data.agentId) {
          this.setAgent(data.agentId)
        }
      } else {
        this.cashbackForm = {
          agentId: this.agentId,
          agentUsername: '',
          agentCredit: 0,
          cashbackType: 'percentage',
          cashbackValue: 0,
          // turningPoint: 0,
          intervalUnit: 'day',
          startDate: '',
          minimumReturn: 0,
          maximumReturn: 0,
          minimumCashbackPerRound: 0,
          maximumCashbackPerRound: 0,
          gameType: 'casino',
          isBetBalance: false,
          isLossBalance: false,
          isActive: true,
          cashbackPerPeoplePerDay: 0, 
        }
      }
    },
    setAgent(agentId) {
      if (this.agentList.length > 0) {
        const found = this.agentList.find((agent) => agent.id === agentId)
        if (found) {
          this.selectedAgent = found
        }
      }
    },
    onSubmit() {
      if (this.isDisplayAll) {
        this.cashbackForm.agentId = this.selectedAgent.id
        this.cashbackForm.agentUsername = this.selectedAgent.username
      } else {
        this.cashbackForm.agentId = this.agentId
      }
      this.cashbackForm.isBetBalance = this.selectedType === 'is_bet_balance'
      this.cashbackForm.isLossBalance = this.selectedType === 'is_loss_balance'
      this.$emit('submit', this.cashbackForm)
    },
  },
}
</script>
